var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""}},[_c('v-tab',{key:"projects",attrs:{"to":{ name: 'projects' }}},[_vm._v(" PROJECTS ")]),_c('v-tab',{key:"generic",attrs:{"to":{
                name: 'projects-generic',
            }}},[_vm._v(" GENERIC ")]),(_vm.tabClosing)?_c('v-tab',{key:"closing",attrs:{"to":{
                name: 'closing-projects',
            }}},[_vm._v(" CLOSING ")]):_vm._e(),_c('v-tab',{attrs:{"to":{
                name: 'nomina-projects',
            }}},[_vm._v(" PAYROLL ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-4 pt-4",style:({ height: `${_vm.height}px` })},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }